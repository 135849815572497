import {
  AssetType,
  IAssetRequestData,
  IFilestackFile,
  IImageAsset,
  AssetActionTypes,
  IAudioAssetContent,
} from '@eulogise/core'
import { EulogiseImageLibrarySortingBy } from '@eulogise/core'

type FetchAssetsByCaseIdPayload = {
  caseId: string
  assetType: AssetType
  sortBy?: EulogiseImageLibrarySortingBy
  customisedImagesOrderIds?: Array<IImageAsset>
}

export type FetchAssetsByCaseIdAction = {
  type: AssetActionTypes.FETCH_ASSETS_BY_CASE_ID
  payload: FetchAssetsByCaseIdPayload
}

export const fetchAssetsByCaseId = (payload: FetchAssetsByCaseIdPayload) => ({
  type: AssetActionTypes.FETCH_ASSETS_BY_CASE_ID,
  payload,
})

export const fetchImageAssetsByCaseId = (caseId: string) => {
  return fetchAssetsByCaseId({ caseId, assetType: AssetType.IMAGE })
}

export const fetchAudioAssetsByCaseId = (caseId: string) =>
  fetchAssetsByCaseId({ caseId, assetType: AssetType.AUDIO })

export const resetAssetState = () => ({
  type: AssetActionTypes.RESET_ASSET_STATE,
})

type SaveAudioFromFileStackPayload = {
  caseId: string
  file: IFilestackFile
  onSuccess: (item: IAudioAssetContent) => void
}

export type SaveAudioFromFileStackAction = {
  type: AssetActionTypes.SAVE_AUDIO_FROM_FILESTACK
  payload: SaveAudioFromFileStackPayload
}

export const saveAudioFromFileStack = (
  payload: SaveAudioFromFileStackPayload,
) => ({
  type: AssetActionTypes.SAVE_ASSET,
  payload,
})

type SaveEditedAudioBufferPayload = {
  caseId: string
  editedAudioFromFileStack: IFilestackFile
  fileName: string
  previousEditedAudioId: string
  key: string
  onSuccess: (item: IAudioAssetContent) => void
}

export type SaveEditedAudioBufferAction = {
  type: AssetActionTypes.SAVE_EDITED_AUDIO_BUFFER
  payload: SaveEditedAudioBufferPayload
}

// This function is only for uploading edited audio via audio trimmer
export const saveEditedAudioBuffer = (
  payload: SaveEditedAudioBufferPayload,
): SaveEditedAudioBufferAction => ({
  type: AssetActionTypes.SAVE_EDITED_AUDIO_BUFFER,
  payload,
})

type SaveAudiosFromFilestackPayload = {
  caseId: string
  files: Array<IFilestackFile>
  onSuccess: (audio: Array<IAudioAssetContent>) => void
}

export type SaveAudiosFromFilestackAction = {
  type: AssetActionTypes.SAVE_AUDIO_FROM_FILESTACK
  payload: SaveAudiosFromFilestackPayload
}

export const saveAudiosFromFileStack = (
  payload: SaveAudiosFromFilestackPayload,
): SaveAudiosFromFilestackAction => ({
  type: AssetActionTypes.SAVE_AUDIO_FROM_FILESTACK,
  payload,
})

type SaveImagePayload = {
  file: IAssetRequestData
}

export type SaveImageAction = {
  type: AssetActionTypes.SAVE_IMAGE
  payload: SaveImagePayload
}

export const saveImage = (payload: SaveImagePayload): SaveImageAction => ({
  type: AssetActionTypes.SAVE_IMAGE,
  payload,
})

type SaveImageWithInsertIndexPayload = {
  file: IAssetRequestData
  newImageInsertIndex?: number
  onSaveNewCustomisedImageOrderIds?: (
    newImageId: string,
    newImageInsertIndex: number,
  ) => void
}

export type SaveImageWithInsertIndexAction = {
  type: AssetActionTypes.SAVE_IMAGE_WITH_INSERT_INDEX
  payload: SaveImageWithInsertIndexPayload
}

export const saveImageWithInsertIndex = (
  payload: SaveImageWithInsertIndexPayload,
): SaveImageWithInsertIndexAction => ({
  type: AssetActionTypes.SAVE_IMAGE_WITH_INSERT_INDEX,
  payload,
})

/*
export const saveImages =
  (files: Array<IAssetRequestData>) => async (dispatch: Dispatch<any>) => {
    for (const file of files) {
      dispatch(saveImage({ file }))
    }
  }
*/

type SaveImageFromFilestackPayload = {
  caseId: string
  file: IFilestackFile
  complete?: () => void
}

export type SaveImageFromFilestackAction = {
  type: AssetActionTypes.SAVE_IMAGE_FROM_FILESTACK
  payload: SaveImageFromFilestackPayload
}

export const saveImageFromFilestack = (
  payload: SaveImageFromFilestackPayload,
): SaveImageFromFilestackAction => ({
  type: AssetActionTypes.SAVE_IMAGE_FROM_FILESTACK,
  payload,
})

type SaveImagesFromFilestackPayload = {
  caseId: string
  files: Array<IFilestackFile>
  complete?: () => void
}

export type SaveImagesFromFilestackAction = {
  type: AssetActionTypes.SAVE_IMAGES_FROM_FILESTACK
  payload: SaveImagesFromFilestackPayload
}

export const saveImagesFromFilestack = (
  payload: SaveImagesFromFilestackPayload,
) => ({
  type: AssetActionTypes.SAVE_IMAGES_FROM_FILESTACK,
  payload,
})

type UpdateImagesOrdersPayload = {
  newOrderImages: Array<IImageAsset>
  complete?: () => void
}

export type UpdateImagesOrdersAction = {
  type: AssetActionTypes.UPDATE_IMAGES_ORDER
  payload: UpdateImagesOrdersPayload
}

export const updateImagesOrders = (payload: UpdateImagesOrdersPayload) => ({
  type: AssetActionTypes.UPDATE_IMAGES_ORDER,
  payload,
})

type UpdateImagesSortByPayload = {
  sortBy: EulogiseImageLibrarySortingBy
  complete?: () => void
}
export type UpdateImagesSortByAction = {
  type: AssetActionTypes.UPDATE_IMAGES_SORTED_BY
  payload: UpdateImagesSortByPayload
}

export const updateImagesSortBy = (
  payload: UpdateImagesSortByPayload,
): UpdateImagesSortByAction => ({
  type: AssetActionTypes.UPDATE_IMAGES_SORTED_BY,
  payload,
})

type RemoveImagePayload = { assetId: string; onSuccess?: () => void }

export type RemoveImageAction = {
  type: AssetActionTypes.REMOVE_ASSET
  payload: RemoveImagePayload
}

export const removeImage = (
  payload: RemoveImagePayload,
): RemoveImageAction => ({
  type: AssetActionTypes.REMOVE_ASSET,
  payload,
})

type UploadingEditedImageStartPayload = { uploadingEditedImageIndex: number }

export const uploadingEditedImageStart = (
  payload: UploadingEditedImageStartPayload,
) => ({
  type: AssetActionTypes.UPLOAD_EDITED_IMAGE,
  payload,
})
